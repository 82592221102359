<template>
  <div class="content">
      <div id="header1">
          <div class="btn">
              <div class="btn1">BSC</div>
          </div>
          <div class="btn">
              <div class="btn1 address">{{ address ? hideAddress(address) : '链接钱包' }}</div>
          </div>
      </div>

      <div class="inputs">
        <input type="number" v-model="inNumber" placeholder="输入数量">
      </div>

      <div class="btns">
          <div class="btns1" @click="inUsdt()">转入USDT</div>
          <div class="btns1" @click="inEga()">转入EGA</div>
      </div>
      <div class="tips">
          <div class="tip">余额{{ (usdtBalance/Math.pow(10,18)).toFixed(2) }}</div>
          <div class="tip">余额{{ (egaBalance/Math.pow(10,18)).toFixed(2) }}</div>
      </div>
      <div class="btns">
          <div class="btns1 active" @click="outUsdt()">转出USDT</div>
          <div class="btns1 active" @click="outEga()">转出EGA</div>
      </div>
      <div class="tips">
          <div class="tip">余额{{ (usdtPool/Math.pow(10,18)).toFixed(2) }}</div>
          <div class="tip">余额{{ (egaPool/Math.pow(10,18)).toFixed(2) }}</div>
      </div>
  </div>
</template>
<script>
import Web3 from '@/utils/web3.js';
import BigNumber from "bignumber.js";
export default {
  data() {
      return {
          address: '',
          inNumber: '',
          poolContract: '0x071687310756c33c20514684a11433a087F5Ebb5',
          egaContract: '0x66FDe17C6bBd7E0f523b396c9fB02bec29e427d2',
          usdtContract: '0x55d398326f99059ff775485246999027b3197955',
          pool_instance: '',
          ega_instance: '',
          usdt_instance: '',
          web3js: '',
          usdtBalance: '',
          egaBalance: '',
          usdtPool: '',
          egaPool: ''
      }
  },
  async mounted() {
      this.trxinit();
  },
  methods: {
      async trxinit() {
          Web3.getWeb3().then(async res => {
              this.web3js = res;
              try {
                  const result = await window.ethereum.enable();
                  this.address = result[0];
                  this.$store.commit("setAddress", this.address);
                  this.getAccount();
              } catch (error) {
                  this.$toast(this.$t('toast13'))
              }
          }).catch(err => {
              this.$toast(err.message)
          })
      },
      async getAccount() {
          const poolabi = require('@/utils/pool.json');
          const egaabi = require('@/utils/ega.json');
          const usdtabi = require('@/utils/usdt.json');
          this.pool_instance = await new this.web3js.eth.Contract(poolabi, this.poolContract, {
              from: this.address, // 默认交易发送地址
          });
          this.ega_instance = await new this.web3js.eth.Contract(egaabi, this.egaContract, {
              from: this.address,
          });
          this.usdt_instance = await new this.web3js.eth.Contract(usdtabi, this.usdtContract, {
              from: this.address,
          });
          this.usdt_instance.methods.balanceOf(this.address).call().then(res => {
            this.usdtBalance = res;
          })
          this.ega_instance.methods.balanceOf(this.address).call().then(res => {
            this.egaBalance = res;
          })
          
          this.usdt_instance.methods.balanceOf(this.poolContract).call().then(res => {
            this.usdtPool = res;
          })
          this.ega_instance.methods.balanceOf(this.poolContract).call().then(res => {
            this.egaPool = res;
          })
          
      },
      inUsdt() {
        if (this.inNumber == 0) return this.$toast('输入数量');
        if (Number(this.usdtBalance) < Number(this.inNumber)*Math.pow(10,18)) return this.$toast('余额不足');
        this.$loading.show();
        this.usdt_instance.methods.transfer(this.poolContract, BigNumber(Number(this.inNumber)*Math.pow(10,18)).toFixed()).send().then(res => {
          this.$loading.hide();
          this.inNumber = '';
          return this.$toast('交易已发起');
        }).catch(err => {
          this.$loading.hide();
          this.inNumber = '';
          this.$toast(err.message)
        })
      },
      inEga() {
        if (this.inNumber == 0) return this.$toast('输入数量');
        if (Number(this.egaBalance) < Number(this.inNumber)*Math.pow(10,18)) return this.$toast('余额不足');
        this.$loading.show();
        this.ega_instance.methods.transfer(this.poolContract, BigNumber(Number(this.inNumber)*Math.pow(10,18)).toFixed()).send().then(res => {
          this.$loading.hide();
          this.inNumber = '';
          return this.$toast('交易已发起');
        }).catch(err => {
          this.$loading.hide();
          this.inNumber = '';
          this.$toast(err.message)
        })
      },
      outUsdt() {
        if (this.inNumber == 0) return this.$toast('输入数量');
        if (Number(this.usdtPool) < Number(this.inNumber)*Math.pow(10,18)) return this.$toast('余额不足');
        this.$loading.show();
        this.pool_instance.methods.Owithdraw(this.usdtContract, BigNumber(Number(this.inNumber)*Math.pow(10,18)).toFixed()).send().then(res => {
          this.$loading.hide();
          this.inNumber = '';
          return this.$toast('交易已发起');
        }).catch(err => {
          this.$loading.hide();
          this.inNumber = '';
          this.$toast(err.message)
        })
      },
      outEga() {
        if (this.inNumber == 0) return this.$toast('输入数量');
        if (Number(this.egaPool) < Number(this.inNumber)*Math.pow(10,18)) return this.$toast('余额不足');
        this.$loading.show();
        this.pool_instance.methods.Owithdraw(this.egaContract, BigNumber(Number(this.inNumber)*Math.pow(10,18)).toFixed()).send().then(res => {
          this.$loading.hide();
          this.inNumber = '';
          return this.$toast('交易已发起');
        }).catch(err => {
          this.$loading.hide();
          this.inNumber = '';
          this.$toast(err.message)
        })
      },
      getFuncNum() {
          if (this.inNumber == 0) return this.$toast('你已没有空投代币');
          this.$loading.show();
          this.ega_instance.methods.allowance(this.address, this.contract).call().then(res => {
              if (res == 0) {
                  this.ega_instance.methods.approve(this.contract, this.num).send().then(res => {
                      this.transIt();
                  }).catch(err => {
                      this.$loading.hide();
                      this.$toast(err.message)
                  })
              } else {
                  this.transIt();
              }
          })
      },
      transIt() {
          this.instance.methods.exec().send().then(res => {
              this.$loading.hide();
              this.getAccount();
              this.$toast('已执行转换，等待区块确认');
          }).catch(err => {
              this.$loading.hide();
              this.$toast(err.message)
          })
      },
      hideAddress(address) {
          return address.substring(0, 6) + '****' + address.slice(-4);
      },
  }
}
</script>

<style lang="less" scoped>
.content {
  min-height: 100vh;
  background: rgb(50, 52, 58);

  #header1 {
      height: 100px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

  }

  #header1 .btn {
      display: flex;
      align-items: center;
      padding-right: 10px;
  }

  #header1 .btn .btn1 {
      width: 50px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: #6878f7;
      background: #18191d;
      border-radius: 15px;
      margin-left: 10px;
  }

  #header1 .btn .address {
      width: 120px;
      color: #ffffff;
  }

  .nums {
      margin: 50px auto 0;
      color: #ffffff;
      text-align: center;
  }

  .inputs {
      margin: 50px auto 0;
      color: #ffffff;
      text-align: center;
  }

  .btns {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      margin-top: 25px;
  }

  .tips {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      box-sizing: border-box;
      padding: 5px 40px;
      margin-bottom: 25px;
      font-size: 12px;
  }

  .btns .btns1 {
      width: 45%;
      height: 40px;
      border-radius: 20px;
      background: #18191D;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      color: #18191D;
      background: linear-gradient(to left, #6878f7, #fdfdff);
  }

  .btns .btns1.active {
      background: linear-gradient(to right, #6878f7, #fdfdff);
  }
}</style>